import 'desktop/src/css/vendor.css';
import 'desktop/src/lib/leaflet.geometryutil.js';
import 'desktop/src/lib/leaflet.snap.js';
import 'leaflet';
import 'proj4';
import 'reflect-metadata';
import 'rxjs';
import 'shared/assets/lib/Leaflet.Editable.js';
import 'shared/assets/lib/Path.Drag.js';
import 'zone.js';
